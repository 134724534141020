/* Custom CSS for the sidebar */
#sidebar {
    height: 100%;
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    padding-top: 20px;
    background-color: RED; /* Sidebar background color */
    color: #ffffff; /* Text color */
    overflow: auto;
  }
  
  #sidebar ul.nav li {
    padding: 3px 9px;
    font-size: 15px;
    border-bottom: 1px solid #4e545c; /* Divider line color */
  }
  
  #sidebar ul.nav li a {
    color: #ffffff; /* Link text color */
    text-decoration: none;
    transition: none !important;
  }
  
  #sidebar ul.nav li a:hover {
    background-color: #495057; /*Hover background color*/
  }
  
  .main-content {
    margin-left: 250px; /* Adjust this value to match the sidebar width */
    padding: 20px;
  }
  
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.render-img {
  width: 120px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.datatable {
  border: 1px solid rgb(209, 204, 204);

}

/* width */
::-webkit-scrollbar {
  width: 7px;
  background: #bc9018;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-track {
  width: 5px;
  height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #D0B000; */
  background: #bc9018;
}

/* .isdzTY {
  background-color: rgb(243, 241, 240) !important;
} */
/* .buGObk{
  background-color: rgb(106, 170, 207) !important;
}  */
.heading {
  font-size: 30px;
}

/* .bPokRt{
  background-color: rgb(243, 241, 240) !important;
} 
.bPokRt:hover{
  background-color: rgb(185, 214, 231) !important;
}

.admin-banner-section{
  background-color: rgb(243, 241, 240);
} */



@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  height: 100;
  /* background-color: #1b1049; */
  color: #fff;
}

.gradient-box {

  align-items: center;
  justify-content: center;
  width: 19rem;
  height: 19rem;
  margin: auto;
  position: relative;
  box-sizing: border-box;

  background: #FFF;
  background-clip: padding-box;
  /* !importanté */
  border: solid 5px transparent;
  /* !importanté */
  border-radius: 50%;
}

.gradient-box:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -23px;
  /* !importanté */
  border-radius: inherit;
  /* !importanté */
  background: linear-gradient(to right, #3B33D5, #0b0b4d);
}

.total-value-earned-per .card {
  text-align: start;
}

.total-value-earned-icon {
  padding: 0px 6px;
  color: #FFF;
  font-size: 30px;
  border-radius: 4px;
}

.total-value-earned-per .card-title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
}

.total-value-earned-per button {
  width: 100%;
}

.form-control {
  box-shadow: none !important;
}

.form-select {
  box-shadow: none !important;
}

.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: none;
  border-left: 3px solid #3B33D5;
  padding: 20px;
}

.logout-button-dash {
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  /* background: linear-gradient(89.66deg, #6a68ef 7.4%, #fc58ff00 110.79%); */
  /* background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%)); */
  /* background: linear-gradient(107deg, rgb(255 97 45) 11.1%, rgb(241 191 130) 95.3%) !important; */
  border: 0;
  background: #bc9018;
  border-radius: 10px;
  font-size: 16px;
  padding: 7px 20px !important;
  margin-left: 8px;
  min-height: 50px;
  min-width: 120px;
  text-align: center;
}

.dashboard-new-admin .navbar {
  border-bottom: 1px solid #adadad2f !important;
}

.btn-add-new-items {
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  background: linear-gradient(89.92deg, #9d44ee 0.05%, #5973ee 98.43%);
  border: 0;
  border-radius: 16px;
  font-size: 14px;
  padding: 10px 20px !important;
}

.dashboard-new-admin .rdt_TableHeader {
  background: linear-gradient(180deg, #2d2f7626, #de705f26);
  color: #FFF;
}

.dashboard-new-admin .rdt_TableHeadRow {
  background-color: #f8f9fa00 !important;
  border-bottom-color: rgba(255, 255, 255, 0.116) !important;
  color: #FFF;
  font-weight: 800;
}

.dashboard-new-admin .rdt_Table {
  background: linear-gradient(180deg, #2d2f7626, #de705f26) !important;
  color: #FFF;
}

.dashboard-new-admin .rdt_TableBody {
  background-color: #f8f9fa00 !important;
  border-bottom-color: rgba(0, 0, 0, 0.041) !important;
  color: #FFF;
  font-weight: 800;
}

.dashboard-new-admin .rdt_TableRow {
  background-color: #f8f9fa00 !important;
  border-bottom-color: rgba(0, 0, 0, 0.041) !important;
  color: #FFF;
}

.dashboard-new-admin .rdt_TableCell {
  color: #FFF;
}

.dashboard-new-admin .rdt_Pagination {
  background-color: #f8f9fa00 !important;
  color: #FFF !important;
}

.dashboard-new-admin .rdt_Pagination button {
  fill: #FFF !important;
}

.edit-modal-design .modal-content {
  background: #2d153a;
}

.form-control {
  background-color: transparent;
  color: #FFF;
  border-color: #ffffff46;
}

.form-control:active,
.form-control:focus {
  background-color: transparent;
  color: #FFF;
  border-color: #ffffff46;
}

.form-select {
  background-color: transparent;
  color: #FFF;
  border-color: #ffffff46;
}

.form-select:active,
.form-select:focus {
  background-color: transparent;
  color: #FFF;
  border-color: #ffffff46;
}

option {
  color: #000;
}

.dashboard-new-admin .form-control {
  width: 500px;
}

.dashboard-new-admin .btn-primary {
  background: linear-gradient(89.66deg, #6a68ef 7.4%, #fc58ff00 110.79%);
  border: transparent;
  border-radius: 16px;
}

.sidebar {
  background-color: rgba(0, 0, 5, 0.603) !important;
  /* padding-top: 100px !important; */
}

#sidebar ul.nav li {
  border-bottom: none !important;
}

.input-lable-alignments {
  /* padding-top: 100px; */
  padding-left: 200px;
}

#sidebar ul.nav li a:hover {
  background: #FFF !important;
  border-radius: 50px;
  color: #000;
  /* background: linear-gradient(107deg, rgb(255 97 45) 11.1%, rgb(241 191 130) 95.3%) !important; */
}

.active {
  background: white;
  background-color: white !important;
  color: black !important;
  border-radius: 30px;
}

.upload-button {
  border: 1px solid rgb(196, 194, 194);
  border-radius: 6px;
  background: linear-gradient(89.66deg, #6a68ef 7.4%, #fc58ff00 110.79%);
  color: white;
}

.small-icons {
  font-size: 15px;
  background-color: #4b4bc9ba;
  border: 0;
  border-radius: 6px;
}

.small-tag-icons {
  color: #6a68ef;

  font-size: 18px;
}

/* background-color: #4b4bc9ba;
  border: 0;
  border-radius: 6px; */

.child-notes {
  top: 69%;
  color: red;
  left: 18%;
}


/* .no-wrap-cell {
  white-space: nowrap;
} */
/* .date{
  margin-left: 94px;
} */
.component-navbar {
  background: linear-gradient(147deg, #3d3423 25.1%, rgb(241 191 130) 95.3%) !important;
  /* background: linear-gradient(107deg, rgb(255 97 45) 11.1%, rgb(241 191 130) 95.3%) !important; */
}

/* .component-tabs{
  background-color: white !important;
} */
/* .component-tabs.active{
  background-color: red !important;
} */
.component-user {
  color: #bc9018;
}

.sc-csKJRI {
  display: flex;
  justify-content: start !important;
}

/* .component-heading{
  margin-top: -70px;
} */
.component-box-input {
  width: 356px;
}

.eye-icon {
  height: 52px;
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 1px solid white !important;
  color: white !important;
  border-left: none !important;
}

.eye-icon-1 {
  height: 38px;
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 1px solid #dee2e6 !important;
  color: #bc9018 !important;
  border-left: none !important;
}

.custom-addsubadmin-inside .eye-icon {
  height: 41px;
  border: 1px solid #dee2e6 !important;
  color: #bc9018 !important;
  border-left: none !important;
}

.add-pass-1 {
  border: 1px solid #dee2e6 !important;
  border-right: none !important;
}

.with-input {
  border-color: 0px solid white !important;
  box-shadow: none !important;
  background-color: rgb(59, 51, 213);
  border: 1px solid white !important;
  color: white !important;
}

.with-input:focus {
  background-color: rgb(59, 51, 213);
  color: white !important;
}

.new-password-positioning {
  position: absolute;
  z-index: 999;
  /* margin-top: -40px;
  margin-bottom: 40px; */
  right: 10px;
  top: 40px;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-addsubadmin>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.custom-addsubadmin {
  border: 0px solid red;
  margin-top: 50px;
}

/* .sub-admins-table .rdt_TableHead, .rdt_TableBody{
  width:70% !important;
} */
/* .table-1 .rdt_TableCol, .table-1 .rdt_TableCell{
  max-width:0% !important;
} */
.table-1 .rdt_Table {
  width: max-content;
  margin: 0 auto !important;
  overflow: scroll;
  white-space: nowrap;
}

.custom-addsubadmin-inside {
  border: 0px solid #bc9018;
  padding: 40px;
  color: #bc9018;
  /* background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%)); */
  border-radius: 20px;

  box-shadow: rgb(217 191 112 / 50%) 6px 2px 16px 0px, rgb(165 140 65 / 50%) -6px -2px 16px 0px;

}

.with-input {
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 1px solid white !important;
  color: white !important;
  border-right: none !important;
}

.eye-icon {
  height: 51px;
  border-color: transparent !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 1px solid white !important;
  color: white !important;
  border-left: none !important;
}

::placeholder {
  color: white !important;
  opacity: 1;
  /* Firefox */
}

.register-btn-1 {
  border: 1px solid #bc9018;
  padding: 10px 40px;
  text-transform: capitalize;
  background-color: #bc9018;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
}

.register-btn-spinner-1 {
  border: 1px solid #bc9018;
  text-transform: capitalize;
  background-color: #bc9018;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
  min-height: 50px;
  min-width: 120px;
}
.user-btn-spinner-1 {
  border: 1px solid #bc9018;
  text-transform: capitalize;
  background-color: #bc9018;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
  min-height: 50px;
  min-width: 180px;
}
.register-btn-4 {
  border: 1px solid #bc9018;
  padding: 5px 0px;
  text-transform: capitalize;
  background-color: #bc9018;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
  min-height: 50px;
  min-width: 120px;
  display: inline-block;
}

.register-btn-copy {
  padding: 5px 15px !important;
  border-radius: 10px !important;
  font-size: 14px !important;
}

.custom-subadmin-modal {
  max-width: 600px;
}

.custom-subadmin-modal-1 {
  color: #bc9018;

  /* background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%)); */
}

.custom-subadmin-modal-2 {
  border: 1px solid #bc9018;
  box-shadow: none !important;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-saled-order text-dark>>>>>>>>>>>>>>>>>>>>>> */
.custom-saled-order {
  border: 0px solid red;

}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #bc9018 !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
  box-shadow: none !important;
}

div:where(.swal2-container) button:where(.swal2-close):hover {
  color: #bc9018 !important;
}

.register-btn-2 {
  border: 1px solid #bc9018;
  padding: 10px 40px;
  text-transform: capitalize;
  background-color: white;
  /* color: #bc9018; */
  color: #bc9018;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
}

.register-btn-2:hover {

  background-color: white !important;
  color: #bc9018;
}

.register-btn-2.active {
  background-color: #bc9018 !important;
  color: white !important;
}

.custom-datascroll {
  overflow: auto;
}

.custom-datascroll th {
  font-size: 13px;
  font-weight: 500;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-saled-order text-dark>>>>>>>>>>>>>>>>>>>>>> */
.custom-saled-order {
  border: 0px solid red;

}

/* .register-btn-2{
  border: 1px solid #ff5604;
  padding: 10px 40px;
  text-transform: capitalize;
  background-color:white;
  color: #ff5604;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
}
.register-btn-2:hover{
  
  background-color:white !important;
  color: #ff5604;
}
.register-btn-2.active{
  background-color:#ff5604 !important;
  color: white !important;
} */
.custom-datascroll {
  overflow: auto;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 14px gray;
  margin: 30px 0px 30px 0px;
  /* padding: 20px; */
}

.custom-datascroll th {
  font-size: 13px;
  font-weight: 500;

}

.custom-datascroll td {
  font-size: 13px !important;

}

.custom-datascroll tr {
  vertical-align: middle;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* .register-btn-4 {
  border: 1px solid green;
  padding: 10px 20px;
  text-transform: capitalize;
  background-color: green;
  color: white;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
} */

.register-btn-3 {
  border: 1px solid red;
  padding: 10px 20px;
  text-transform: capitalize;
  background-color: red;
  color: white;
  /* border-radius: 10px; */
  font-size: 16px;
  text-transform: uppercase;
}

.register-btn-5 {
  border: 1px solid #bc9018;
  padding: 5px 10px;
  text-transform: capitalize;
  background-color: #bc9018;
  color: white;
  border-radius: 10px;
  font-size: 14px;
  text-transform: uppercase;
}

.custom-pushbtn {
  width: 160px;
}

.custom-select-btn {
  background-color: #bc9018;
  color: white;
  padding: 10px;
  /* border-radius: 5px; */
}

.custom-select-btn-5 {
  background-color: #bc9018 !important;
  color: white !important;
  padding: 5px !important;
  border-radius: 10px;
  font-size: 14px;
}

.custom-select-btn-5:focus-visible {
  border: 0px;
  outline: #bc9018;
}

.fade-disabled {
  opacity: 0.5 !important;
  /* Adjust the opacity value as needed */
  pointer-events: none;
  /* Disable pointer events to make the button unclickable */
}


/* <<<<<<<<<<<<<<<<<<<<<<<<<<<dashboard>>>>>>>>>>>>>>>>>>>>> */
#mobile-three-line-collapse {
  font-size: 40px;
  margin-left: 30px;
  margin-top: 30px;
}

#mobile-close-collapse {
  font-size: 20px;
}

#mySidenav {
  transition: 0.5s;
}

.navbar {
  padding: 0;
}


.navbar .nav-link,
.navbar .nav-link:hover,
.navbar .nav-link:active {
  color: #FFF;
  background-color: transparent;
}

.navbar-brand img {
  width: 80px;
}

.navbar-brand-footer img {
  width: 80px;
  height: auto;
}


@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');




#mobile-three-line-collapse {
  font-size: 40px;
  margin-left: 30px;
  margin-top: 30px;
  color: black;
}

#mobile-close-collapse {
  font-size: 20px;
}

#mySidenav {
  transition: 0.5s;
}



/* ============ desktop view ============ */
@media all and (min-width: 992px) {

  #mobile-close-arrow-left {
    font-size: 18px;
    position: relative;
    left: 14px;
    top: 10px;
    border: 1px solid rgba(0, 0, 0, 0.411);
    border-radius: 50%;
    background: rgb(166, 188, 255);
    padding: 2px 6px;
    line-height: 18px;
    cursor: pointer;
  }

  #mobile-close-arrow-right {
    font-size: 18px;
    position: relative;
    left: 14px;
    top: 10px;
    border: 1px solid rgba(0, 0, 0, 0.411);
    border-radius: 50%;
    background: rgb(166, 188, 255);
    padding: 2px 6px;
    line-height: 18px;
    cursor: pointer;
  }



  .navbar-brand img {
    width: 120px;
  }

  .navbar-brand-footer img {
    width: 80px;
    height: auto;
  }

  .timeline-image-arrow {
    width: 86%;
    margin: auto;
  }


  #mobile-close-collapse {
    display: none;
  }

  #mobile-three-line-collapse {
    display: none;
  }


  .dashboard-profile-staked-2 .nav-link {
    padding: 0px;
    text-decoration: underline;
    font-size: 14px;
  }

  .dashboard-profile-staked-2 .nav-pills .nav-link.active,
  .dashboard-profile-staked-2 .nav-pills .show>.nav-link {
    color: #0d6efd;
    background-color: transparent;
  }

  .form-control-stake-deposit-address,
  .form-control-stake-deposit-address:focus {
    width: 80%;
    margin: auto;
    box-shadow: #000 0px 15px 20px -20px;
    padding: 1rem 0.75rem;
    font-size: 0.9rem;
    border: 1px solid #8a8a8a8e;
    background: white;
    color: #000;
  }


  .dashboard-profile-text-4 {
    font-size: 14px;
    color: rgb(0, 119, 255) !important;
    text-decoration: underline;
  }

  .dashboard-profile-text-3 {
    font-size: 13px;
    margin-bottom: 6px;
  }

  .dashboard-profile-text-2 {
    font-size: 14px;
    margin-bottom: 6px;
  }

  .dashboard-profile-staked-2 .card {
    border-bottom: 6px solid #15db95;
    background-color: #e0e1e233;
  }

  .dashboard-profile-text-2 {
    font-size: 14px;
    font-weight: 600;
  }

  .dashboard-profile-text-1 {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .dashboard-profile-staked-1 {
    background-color: #e0e1e233;
    padding: 20px 20px;
    border-radius: 4px;
    border-left: 5px solid #1668dd !important;
  }

  .btn-profile-update {
    background-color: #8a8a8a;
    padding: 8px 20px;
    color: #FFF;
    border: 1px solid #8a8a8a;
    border-radius: 0.25rem;
  }

  .dashboard-profile-section .form-control-address {
    padding: 0.600rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: white;
    background-clip: padding-box;
    border-bottom: 1px solid #ced4da !important;
    border-radius: 0rem;
    box-shadow: none !important;
    border: none;
  }

  .dashboard-profile-section .form-control-name {
    padding: 0.600rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: none !important;
    border-right: 0px;
  }

  .avatar-upload {
    position: relative;
    max-width: 205px;
  }

  .avatar-upload .avatar-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
  }

  .avatar-upload .avatar-edit input {
    display: none;
  }

  .avatar-upload .avatar-edit input+label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #15db96;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
  }

  .avatar-upload .avatar-edit input+label:after {
    content: "\f067";
    font-family: 'FontAwesome';
    color: #FFF;
    position: absolute;
    top: 4px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
  }

  .avatar-upload .avatar-preview {
    width: 192px;
    height: 192px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .avatar-upload .avatar-preview>div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }


  .dashboard-sidenav-section .nav-pills .nav-link.active,
  .dashboard-sidenav-section .nav-pills .show>.nav-link {
    background-color: transparent;
    background-image: url('../src/assets/Image/Rectangle29.png');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    color: #ffffff !important;
    position: relative;

  }

  .dashboard-sidenav-section .nav-link.active::after {
    content: "";
    position: absolute;
    background-color: transparent;
    bottom: 100%;
    right: 0;
    /* height: 35px;
      width: 35px; */
    /* border-bottom-right-radius: 18px; */
    box-shadow: 0 20px 0 0 white;
  }

  .dashboard-sidenav-section .nav-link.active::before {
    content: "";
    position: absolute;
    background-color: transparent;
    top: 100%;
    right: 0;
    /* height: 35px;
      width: 35px; */
    /* border-top-right-radius: 18px; */
    box-shadow: 0 -20px 0 0 white;
  }

  .dashboard-navbar-brand img {
    width: 80px;
    height: 80px;
    margin: auto;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .dashboard-sidenav-section .nav-pills .nav-link {
    padding: 13px 15px;
    text-decoration: none;
    font-size: 15px;
    color: white !important;
    display: block;
    margin-top: 10px;
    border-radius: 0px;
    text-align: left;
    margin-left: 10px;
    cursor: pointer !important;
    /* border-top-left-radius: 50px;
      border-bottom-left-radius: 50px; */

  }

  .dashboard-sidenav-section .nav-link .bi {
    margin-right: 20px;
    font-size: 22px;
    margin-left: 10px;
    position: relative;
    top: 2px;
  }

  .dashboard-main-navbar {
    margin-left: 400px;
  }

  .dashboard-sidenav-section {
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    /* background: #F5F5F5; */
    /* background: linear-gradient(107deg, rgb(255 255 255) 11.1%, rgb(255 205 55) 95.3%); */
    /* background: var(--Linear, linear-gradient(1deg, #BC9018 0.5%, rgba(255, 255, 255, 0.00) 97.65%)); */
    background: linear-gradient(147deg, #3d3423 25.1%, rgb(241 191 130) 95.3%) !important;
    color: #000 !important;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);


    /* overflow: hidden; */
  }

  .dashboard-main-section {
    transition: 0.6s;
    margin-left: 250px;
  }


  .auth-form__btn {
    text-transform: uppercase;
    background: transparent;
    border: 1px solid #EEE;
    padding: 10px 20px;
    width: 40%;
    font-size: 16px;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }

  .login-banner-tabs-section {
    min-height: 100vh;
  }



  .login-banner-tabs-section ::placeholder {
    color: #FFF
  }

  .form-control-login {
    display: block;
    width: 50%;
    margin: auto;
    padding: 0.675rem 0.75rem;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #FFF;
    background-color: #15db95;
    background-clip: padding-box;
    border: none !important;
    outline: none !important;
    border-radius: 0.25rem;
  }

  .tabs-automatic-login-button {
    background: rgb(78, 119, 241);
    padding: 10px 20px;
    width: 40%;
    font-size: 16px;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 6px;
    border: none;
    box-shadow: #011f4a 0px 25px 50px -12px;

  }

  .login-tabs-section .nav-pills {
    background: rgb(78, 119, 241);
    width: max-content;
    margin: auto;
    padding: 5px 5px;
    border-radius: 50px;
    box-shadow: #011f4a 0px 25px 50px -12px;

  }

  .login-tabs-section .nav-pills .nav-link {
    color: #FFF;
    padding: 10px 30px;
    border-radius: 50px;
  }

  /* 
  .login-tabs-section .nav-pills .nav-link.active,
  .login-tabs-section .nav-pills .show>.nav-link {
      color: #fff;
      background: rgb(78, 119, 241)
  } */

  .login-tabs-section-border {
    /* border: 1px solid #ffffff; */
    background: linear-gradient(to left, #3f52af, #478ebd);

    width: 80%;
    padding: 60px 30px;
    margin: auto;
    border-radius: 15px;
  }

  .tabs-login-text-1 {
    font-size: 24px;
  }

  .tabs-login-text-2 {
    font-size: 14px;
  }

  .footer-icons-1 {
    font-size: 30px;
    margin-right: 20px;
    color: #FFF;
  }

  .footer-text-2 {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .footer-text-1 {
    font-size: 14px;
    margin-bottom: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .top-banner-section {
    min-height: 90vh;

  }

  /* .top-grid-banner-section{
  background-image: url("./images/banner/banner-bg-image.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  
} */



  .rotating-tabs-heading {
    font-size: 20px;
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 100px !important;
    width: 50%;
    text-align: center;
    margin: auto;
    background: transparent linear-gradient(110deg, #15D390 0%, #ccc 100%) 0% 0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  /* .banner-third-section{
  background: #a8ff78; 
  background: -webkit-linear-gradient(to right, #78ffd6, #a8ff78);
  background: linear-gradient(to right, #78ffd6, #a8ff78);
} */
  .platform-table-section {
    width: 100%;
    height: 600px;
    overflow-y: scroll;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid rgba(204, 204, 204, 0.075);
    border-bottom: 0px;
    box-shadow: 0 4px 4px rgb(0 0 0 / 5%), 0 1px 6px rgb(0 0 0 / 5%), 0 8px 8px rgb(0 0 0 / 5%), 0 16px 16px rgb(0 0 0 / 5%), 8px 32px 32px rgb(0 0 0 / 2%), 8px 64px 64px rgb(0 0 0 / 2%);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 20px 20px 0px 20px;
  }

  .platform-table-section-bottom {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid rgba(204, 204, 204, 0.075);
    border-top: 0px;
    box-shadow: 0 4px 4px rgb(0 0 0 / 5%), 0 1px 6px rgb(0 0 0 / 5%), 0 8px 8px rgb(0 0 0 / 5%), 0 16px 16px rgb(0 0 0 / 5%), 8px 32px 32px rgb(0 0 0 / 2%), 8px 64px 64px rgb(0 0 0 / 2%);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 20px 20px 20px 20px;
  }

  .platform-table-section img {
    width: 30px;
    height: 30px;
  }

  .platform-table-section .flex-row {
    padding: 20px 10px;
    border-bottom: 1px solid rgba(99, 97, 97, 0.199);
  }

  .table-data-2 {
    background-color: #15db95;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 10px 12px;
    font-size: 15px;
    color: #FFF;
  }

  .table-data-3 {
    background-color: #cef8e991;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    padding: 8px 16px;
    font-size: 14px;
  }

  .table-data-5 {
    font-size: 14px;
  }

  .table-data-41 {
    font-size: 14px;
  }

  .table-data-42 {
    font-size: 16px;
  }

  .table-data-43 {
    font-size: 16px;
    color: #15db95;
  }

  .table-see-more-button {
    background-color: #8a8a8a;
    padding: 10px;
    border: none;
    border-radius: 8px;
    color: #FFF;
  }

  #line-glow-css {
    width: 16%;
    height: 3px;
    background-color: #15db95;
    border-radius: 40px;
  }

  .bannner-text-1 {
    font-family: 'Rubik', sans-serif;
    font-size: 6.5rem;
    color: #15db95;
    font-weight: 100;
    letter-spacing: 8px;
  }

  .bannner-text-2 {
    font-family: 'Roboto', sans-serif;
    font-size: 5.5rem;
    font-weight: 1000;
    letter-spacing: 6px;
    position: relative;
    top: -20px;
  }

  .bannner-text-3 {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .bannner-text-4 {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .banner-second-section {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .banner-second-section .card {
    border: 0px;
    height: 150px !important;
    background: transparent;
    /* padding: 20px;
  background-image: url("./images/world1.png"), url("./images/banner/card-bg.png");
  background-position: right bottom, left top;
  background-repeat: no-repeat, no-repeat;
  background-size: 90px 90px, 100% 100%;
  border: 1px solid rgba(99, 97, 97, 0.199); */
  }

  .banner-second-section-card-1 {
    margin-top: 180px;
  }

  .banner-second-section-card-2 {
    margin-top: 120px;
  }

  .banner-second-section-card-3 {
    margin-top: 60px;
  }

  .banner-section-heading {
    font-family: 'Roboto', sans-serif;
    font-size: 2.5rem;
    font-weight: 1000;
    letter-spacing: 1px;
    text-transform: uppercase;
  }



}



/* ============ mobile view ============ */
@media(max-width: 991px) {
  .register-bg-styling-css .min-vh-100 {
    min-height: auto !important;
    padding-top: 100px;
  }

  .login-bg-styling-css .min-vh-100 {
    min-height: auto !important;
    padding-top: 100px;
  }

  .card-logo-image {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }

  .banner-timeline-section .card-image {
    width: 102px;
    height: 102px;
    margin: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px -1px 23px #C5C252;
    border: 1px solid #ffffff;
    border-radius: 30px;
    opacity: 1;
    padding: 20px;
    /* margin-top: -100px; */
    margin-bottom: 40px;
  }

  .button-dashboard-table-back {
    background: transparent;
    font-size: 30px;
    border: 1px solid rgba(0, 0, 0, 0.411);
    border-radius: 50%;
    padding: 2px 6px;
    line-height: 30px;
    cursor: pointer;
    width: fit-content;
  }

  #mobile-close-arrow-left {
    display: none !important;
  }

  #mobile-close-arrow-right {
    display: none !important;
  }

  .glowing-button6 {
    position: relative;
    background-color: #15db95;
    box-shadow: 0 0 10px 0 #15db95;
    border: 2px solid #15db95;
    padding: 8px 20px !important;
    background-color: transparent;
    transition: .4s;
    font-size: 14px;
    cursor: pointer;
    color: #15db95;
    z-index: 0;
  }

  .navbar-brand img {
    width: 80px;
  }

  .navbar-brand-footer img {
    width: 80px;
    height: auto;
  }

  .timeline-image-arrow {
    display: none;
  }

  .banner-followus-section i {
    font-size: 42px;
    background: #cac5318f;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f3f9a7b6, #cac531be);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f3f9a7b6, #cac531be);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 66px;
  }

  .follow-us-text-1 {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 900;
  }

  .banner-followus-section .card {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    padding: 10px;
    text-align: center;
  }

  .platform-button-top {
    background: #CAC531;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F3F9A7, #CAC531);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F3F9A7, #CAC531);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 4px 20px;
    border: 0px;
    /* position: absolute;
  top: -16px; */
    border-radius: 12px;
    /* left: 33%; */
    width: fit-content;
    margin: auto;
    margin-top: 30px;
  }

  .platform-button-bottom {
    background: #CAC531;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #F3F9A7, #CAC531);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #F3F9A7, #CAC531);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 4px 20px;
    border: 0px;
    /* position: absolute;
  bottom: -16px; */
    border-radius: 12px;
    /* left: 33%; */
    width: fit-content;
    margin: auto;
    margin-top: 30px;
  }

  .banner-timeline-section .card .card-title {
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .banner-timeline-section .card .card-text {
    font-size: 15px;
  }

  .banner-timeline-section .card {
    font-family: 'Jost', sans-serif;
    width: 85%;
    margin: auto;
    background: #a8ff78;
    background: -webkit-linear-gradient(to right, #78ffd72f, #a7ff7836);
    background: linear-gradient(to right, #78ffd72f, #a7ff7836);
    border: 1px solid #ffffff23;
    padding: 20px;
    border-radius: 8px;
  }

  .tabs-level-based-section {
    margin-top: 20px;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 20vh;
  }

  .level-based-button {
    border: 0px;
    background-color: transparent;
    color: #0d6efd;
    text-decoration: underline;
  }

  .uix-tabs__nav .active img {
    width: 110px;
    height: 110px;
    margin-left: -25px;
    margin-top: -18px;
  }

  .uix-tabs__nav img {
    width: 80px;
    height: 80px;
    margin-left: -5px;
  }

  .uix-tabs__content p {
    font-size: 14px;
  }

  .bannner-text-5 {
    font-size: 22px;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .card-left-image {
    margin-top: 70px;
    width: 20px;
    height: 140px;
  }

  .heading-right-image {
    display: none;
  }

  .team-view-staked-top-section {
    width: 500px;
    background-color: #e0e1e233;
    padding: 10px 20px;
    border-radius: 4px;
    border-left: 5px solid #15db95 !important;
    /* position: absolute;
  right: 30px;
  top: 30px; */
  }

  .banner-video-1 {
    width: 100%;
    height: 100%;
  }

  .total-level-text-1 {
    font-size: 12px;
    margin-bottom: 0px;
    line-height: 16px;
  }

  .total-level-text-2 {
    font-size: 12px;
    margin-bottom: 0px;
    line-height: 16px;
    font-weight: 600;
  }

  .glow-dots-banner-image-3 {
    width: 400px;
    height: 400px;
    position: absolute;
    top: -150px;
    right: -150px;
    z-index: -1;
  }

  .glow-dots-banner-image-2 {
    width: 60px;
    height: 60px;
    position: relative;
    top: -30px;
    left: 20px;
    z-index: -1;
  }

  .glow-dots-banner-image-1 {
    width: 60px;
    height: 60px;
    position: relative;
    top: 30px;
    left: 20px;
  }

  .dashboard-profile-staked-2 .nav-link {
    padding: 0px;
    text-decoration: underline;
    font-size: 14px;
  }

  .dashboard-profile-staked-2 .nav-pills .nav-link.active,
  .dashboard-profile-staked-2 .nav-pills .show>.nav-link {
    color: #0d6efd;
    background-color: transparent;
  }

  .form-control-stake-deposit-address,
  .form-control-stake-deposit-address:focus {
    width: 80%;
    margin: auto;
    box-shadow: #000 0px 15px 20px -20px;
    padding: 1rem 0.75rem;
    font-size: 0.9rem;
    border: 1px solid #8a8a8a8e;
    background: white;
    color: #000;
  }

  .dashboard-profile-text-6 {
    margin: auto;
    font-size: 13px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }

  .dashboard-profile-text-5 {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .metamaskModal .btn-close {
    box-shadow: none !important;
  }

  .metamaskModal .modal-content {
    background: linear-gradient(to right, #3f52af, #478ebd);
  }

  .metamaskModal-inner-section {
    background: linear-gradient(to left, #3f52af, #478ebd);
    border: 1px solid #8a8a8a;
    padding: 10px;
    margin: 30px 0px;
    border-radius: 6px;
  }

  .metamaskModal-inner-section img {
    width: 50px;
    height: 50px;
  }

  .metamaskModal-inner-section h5 {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
  }

  .metamaskModal {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .metamaskModal .modal-title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    color: #fff;
  }

  .dashboard-profile-text-4 {
    font-size: 14px;
    color: rgb(0, 119, 255) !important;
    text-decoration: underline;
  }

  .dashboard-profile-text-3 {
    font-size: 13px;
    margin-bottom: 6px;
  }

  .dashboard-profile-text-2 {
    font-size: 14px;
    margin-bottom: 6px;
  }

  .dashboard-profile-staked-2 .card {
    border-bottom: 6px solid #15db95;
    background-color: #e0e1e233;
  }

  .dashboard-profile-text-2 {
    font-size: 14px;
    font-weight: 600;
  }

  .dashboard-profile-text-1 {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .dashboard-profile-staked-1 {
    background-color: #e0e1e233;
    padding: 20px 20px;
    border-radius: 4px;
    border-left: 5px solid #1668dd !important;
  }

  .btn-profile-update {
    background-color: #8a8a8a;
    padding: 8px 20px;
    color: #FFF;
    border: 1px solid #8a8a8a;
    border-radius: 0.25rem;
  }

  .dashboard-profile-section .form-control-address {
    padding: 0.600rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: white;
    background-clip: padding-box;
    border-bottom: 1px solid #ced4da !important;
    border-radius: 0rem;
    box-shadow: none !important;
    border: none;
  }

  .dashboard-profile-section .form-control-name {
    padding: 0.600rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: none !important;
    border-right: 0px;
  }

  .avatar-upload {
    position: relative;
    max-width: 205px;
  }

  .avatar-upload .avatar-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
  }

  .avatar-upload .avatar-edit input {
    display: none;
  }

  .avatar-upload .avatar-edit input+label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #15db96;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
  }

  .avatar-upload .avatar-edit input+label:after {
    content: "\f067";
    font-family: 'FontAwesome';
    color: #FFF;
    position: absolute;
    top: 4px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
  }

  .avatar-upload .avatar-preview {
    width: 192px;
    height: 192px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #F8F8F8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .avatar-upload .avatar-preview>div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .dashboard-sidenav-section .nav-pills .nav-link.active,
  .dashboard-sidenav-section .nav-pills .show>.nav-link {
    background-color: white;
    color: #000 !important;
    position: relative;
  }

  /* .dashboard-sidenav-section .nav-link.active::after {
      content: "";
      position: absolute;
      background-color: transparent;
      bottom: 100%;
      right: 0;
      height: 35px;
      width: 35px;
      border-bottom-right-radius: 18px;
      box-shadow: 0 20px 0 0 white;
  } */

  /* .dashboard-sidenav-section .nav-link.active::before {
      content: "";
      position: absolute;
      background-color: transparent;
      top: 100%;
      right: 0;
      height: 35px;
      width: 35px;
      border-top-right-radius: 18px;
      box-shadow: 0 -20px 0 0 white;
  } */

  .dashboard-sidenav-section .nav-pills .nav-link.active,
  .dashboard-sidenav-section .nav-pills .show>.nav-link {
    background-color: white;
    color: #000 !important;
    position: relative;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  /* .dashboard-sidenav-section .nav-link.active::after {
      content: "";
      position: absolute;
      background-color: transparent;
      bottom: 100%;
      right: 0;
      height: 35px;
      width: 35px;
      border-bottom-right-radius: 18px;
      box-shadow: 0 20px 0 0 white;
  } */

  /* .dashboard-sidenav-section .nav-link.active::before {
      content: "";
      position: absolute;
      background-color: transparent;
      top: 100%;
      right: 0;
      height: 35px;
      width: 35px;
      border-top-right-radius: 18px;
      box-shadow: 0 -20px 0 0 white;
  } */

  .dashboard-navbar-brand img {
    width: 80px;
    height: 80px;
    margin: auto;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .dashboard-sidenav-section .nav-pills .nav-link {
    padding: 16px 16px;
    text-decoration: none;
    font-size: 13px;
    color: #000000 !important;
    display: block;
    margin-top: 14px;
    border-radius: 0px;
    text-align: left;
    margin-left: 10px;

    text-transform: uppercase;

  }

  .dashboard-sidenav-section .nav-link .bi {
    margin-right: 20px;
    font-size: 22px;
    margin-left: 10px;
    position: relative;
    top: 2px;
  }

  .dashboard-main-navbar {
    margin-left: 400px;
  }

  .dashboard-sidenav-section {
    height: 100%;
    width: 0px;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    /* background: linear-gradient(to right, #3B33D5, #0b0b4d); */
    background: linear-gradient(107deg, rgb(255 97 45) 11.1%, rgb(241 191 130) 95.3%);

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    overflow: hidden;
  }

  .dashboard-main-section {
    margin-left: 0px;
    transition: 0.6s;
  }


  .auth-form__btn {
    text-transform: uppercase;
    background: transparent;
    border: 1px solid #EEE;
    padding: 10px 20px;
    width: 40%;
    font-size: 16px;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }

  .login-banner-tabs-section {
    min-height: 100vh;
  }

  .login-banner-tabs-section ::placeholder {
    color: #FFF
  }

  .form-control-login {
    display: block;
    width: 50%;
    margin: auto;
    padding: 0.675rem 0.75rem;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #FFF;
    background-color: #15db95;
    background-clip: padding-box;
    border: none !important;
    outline: none !important;
    border-radius: 0.25rem;
  }

  .tabs-automatic-login-button {
    background: rgb(78, 119, 241);
    padding: 10px 20px;
    font-size: 16px;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 6px;
    border: none;
    box-shadow: #011f4a 0px 25px 50px -12px;

  }

  .login-tabs-section .nav-pills {
    background: rgb(78, 119, 241);
    width: max-content;
    margin: auto;
    padding: 5px 5px;
    border-radius: 50px;
    box-shadow: #011f4a 0px 25px 50px -12px;


  }

  .login-tabs-section .nav-pills .nav-link {
    color: #FFF;
    padding: 6px 20px;
    border-radius: 50px;
    font-size: 12px;
  }

  .login-tabs-section .nav-pills .nav-link.active,
  .login-tabs-section .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #15db95;
  }

  .login-tabs-section-border {
    background: linear-gradient(to left, #3f52af, #478ebd);

    /* border: 1px solid #ffffff; */
    padding: 30px 30px;
    margin: auto;
    border-radius: 15px;
  }

  .tabs-login-text-1 {
    font-size: 24px;
  }

  .tabs-login-text-2 {
    font-size: 14px;
  }

  .footer-icons-1 {
    font-size: 30px;
    margin-right: 20px;
    color: #FFF;
  }

  .footer-text-2 {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .footer-text-1 {
    font-size: 14px;
    margin-bottom: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .top-banner-section {
    min-height: 90vh;

  }

  .rotating-tabs-heading {
    font-size: 20px;
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 100px !important;
    width: 50%;
    text-align: center;
    margin: auto;
    background: transparent linear-gradient(110deg, #15D390 0%, #ccc 100%) 0% 0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }


  .platform-table-section {
    width: 100%;
    height: 600px;
    overflow-y: scroll;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid rgba(204, 204, 204, 0.075);
    border-bottom: 0px;
    box-shadow: 0 4px 4px rgb(0 0 0 / 5%), 0 1px 6px rgb(0 0 0 / 5%), 0 8px 8px rgb(0 0 0 / 5%), 0 16px 16px rgb(0 0 0 / 5%), 8px 32px 32px rgb(0 0 0 / 2%), 8px 64px 64px rgb(0 0 0 / 2%);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 10px 10px 0px 10px;
  }

  .platform-table-section-bottom {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border: 1px solid rgba(204, 204, 204, 0.075);
    border-top: 0px;
    box-shadow: 0 4px 4px rgb(0 0 0 / 5%), 0 1px 6px rgb(0 0 0 / 5%), 0 8px 8px rgb(0 0 0 / 5%), 0 16px 16px rgb(0 0 0 / 5%), 8px 32px 32px rgb(0 0 0 / 2%), 8px 64px 64px rgb(0 0 0 / 2%);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 20px 20px 20px 20px;
  }

  .platform-table-section img {
    width: 20px;
    height: 20px;
  }

  .platform-table-section .flex-row {
    padding: 20px 10px;
    border-bottom: 1px solid rgba(99, 97, 97, 0.199);
  }

  .table-data-2 {
    background-color: #15db95;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 4px 6px;
    font-size: 10px;
    color: #FFF;
  }

  .table-data-3 {
    background-color: #cef8e991;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    padding: 8px 10px;
    font-size: 12px;
    display: flex;
  }

  .table-data-5 {
    font-size: 12px;
  }

  .table-data-4 {
    display: flex;
  }

  .table-data-41 {
    font-size: 12px;
  }

  .table-data-42 {
    font-size: 12px;
  }

  .table-data-43 {
    font-size: 12px;
    color: #15db95;
  }

  .table-see-more-button {
    background-color: #8a8a8a;
    padding: 6px;
    border: none;
    border-radius: 8px;
    color: #FFF;
  }

  #line-glow-css {
    width: 16%;
    height: 3px;
    background-color: #15db95;
    border-radius: 40px;
  }

  .bannner-text-22 {
    font-size: 14px;
  }

  .bannner-text-1 {
    font-family: 'Rubik', sans-serif;
    font-size: 4.5rem;
    color: #15db95;
    font-weight: 100;
    letter-spacing: 8px;
  }

  .bannner-text-2 {
    font-family: 'Roboto', sans-serif;
    font-size: 3.5rem;
    font-weight: 1000;
    letter-spacing: 6px;
    position: relative;
    top: -20px;
  }

  .bannner-text-3 {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .bannner-text-4 {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .banner-second-section {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .banner-second-section .card {
    border: 0px;
    height: 150px !important;
    background: transparent;
    /* padding: 20px;
  background-image: url("./images/world1.png"), url("./images/banner/card-bg.png");
  background-position: right bottom, left top;
  background-repeat: no-repeat, no-repeat;
  background-size: 90px 90px, 100% 100%;
  border: 1px solid rgba(99, 97, 97, 0.199); */
  }


  .banner-section-heading {
    font-family: 'Roboto', sans-serif;
    font-size: 2.5rem;
    font-weight: 1000;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

/* ============ Common CSS ============ */



.wallet-table-section-tabs table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.wallet-table-section-tabs table tbody {
  padding: .35em;
  border-top: 0px !important;
}

.wallet-table-section-tabs table th {
  background-color: #15db95 !important;
}

.wallet-table-section-tabs table tbody tr {
  font-size: 14px;
  border: 1px solid #ccc;
}

.wallet-table-section-tabs table th:nth-child(1) {
  border-top-left-radius: 8px;
}

.wallet-table-section-tabs table th:last-child {
  border-top-right-radius: 8px;
}

.wallet-table-section-tabs table th,
.wallet-table-section-tabs table td {
  padding: .625em;
  text-align: center;
}

.wallet-table-section-tabs table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  .wallet-table-section-tabs table {
    border: 0;
  }

  .wallet-table-section-tabs table caption {
    font-size: 1.3em;
  }

  .wallet-table-section-tabs table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .wallet-table-section-tabs table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }

  .wallet-table-section-tabs table td {
    display: block;
    font-size: .8em;
    text-align: right;
  }

  .wallet-table-section-tabs table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .wallet-table-section-tabs table td:last-child {
    border-bottom: 0;
  }
}

.tableFixHead {
  overflow: auto;
  height: 500px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
}


.swiper {
  padding-bottom: 100px;
}

.swiper .card {
  padding: 50px 10px;
  background: transparent linear-gradient(92deg, #00000000 0%, #15db95 100%) 0% 0% no-repeat padding-box;
  border: 0px !important;
  border-radius: 20px;

}

.swiper .card img {
  width: 100px;
  height: 100px;
  margin: auto;
}

.swiper .card .card-title {
  font-size: 20px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: 800;
  text-transform: uppercase;
}

.swiper .card .card-text {
  font-size: 15px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.swiper-button-prev {
  position: absolute;
  top: 90%;
  left: 46%;
  border: 1px solid #15D390;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #15D390;
  opacity: 1;
}

.swiper-button-next {
  position: absolute;
  top: 90%;
  right: 46%;
  border: 1px solid #15D390;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #15D390;
  opacity: 1;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 1;
  pointer-events: all;
}


.connectwallet-btn {
  background: #d79332;
  background: linear-gradient(to bottom, #d79332 0%, #15db95 19%, #f8e852 46%, #f8e852 63%, #15db95 83%, #e1aa3d 100%);
  padding: 10px 20px !important;
  border-radius: 8px;
}




/* glowing button 6 */


.glowing-button6::before {
  content: '';
  width: 100%;
  height: calc(50% + 1px);
  background-color: #15db95;
  position: absolute;
  left: 0;
  top: 0;
  border: 2px solid #15db95;
  transition: .4s;
  box-shadow: 0 0 1px 0 #15db95;
  z-index: -1;
  width: 10px;
  height: 10px;
  top: -5px;
  left: -5px;
  background-color: black;
  border-radius: 50%;
}

.glowing-button6::after {
  content: '';
  width: 100%;
  height: calc(50% + 1px);
  background-color: #15db95;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 2px solid #15db95;
  transition: .4s;
  box-shadow: 0 0 1px 0 #15db95;
  z-index: -1;
  width: 10px;
  height: 10px;
  bottom: -5px;
  right: -5px;
  background-color: black;
  border-radius: 50%;
}

.glowing-button6:hover::before {
  left: calc(100% - 5px);
}

.glowing-button6:hover::after {
  right: calc(100% - 5px);
}


/*-------------vertical-tree-view------------*/
.vertical-tree {
  padding-top: 40px;
  padding-bottom: 40px;
}

.vertical-tree ul {
  padding-left: 30px;
}

.vertical-tree li {
  margin: 0px 0;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0px 5px;
}

.vertical-tree li::before {
  content: '';
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  right: auto;
  left: -20px;
  border-left: 2px solid #ccc;
  bottom: 50px;
}

.vertical-tree li::after {
  content: '';
  position: absolute;
  top: 34px;
  width: 25px;
  height: 20px;
  right: auto;
  left: -20px;
  border-top: 2px solid #ccc;
}

.vertical-tree li a {
  background-color: #9fffdd;
  color: #222;
  padding: 10px;
  display: inline-block;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  /* height: 50px; */
  width: 200px;
  word-wrap: break-word;
  line-height: 40px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 4px;
}

.vertical-tree>ul>li::before,
.vertical-tree>ul>li::after {
  border: 0;
}

.vertical-tree li:last-child::before {
  height: 34px;
}

/* .vertical-tree li a:hover, 
.vertical-tree li a:hover+ul li a {
  background-color: #5a8dee;
  color: #fff;
  border: 1px solid #5a8dee;
} */
.vertical-tree li a:hover+ul li::after,
.vertical-tree li a:hover+ul li::before,
.vertical-tree li a:hover+ul::before,
.vertical-tree li a:hover+ul ul::before {
  border-color: #15db95;
}

#more {
  display: none;
}

#myBtn {
  background-color: transparent;
  border: 0px;
  color: #FFF;
  font-size: 14px;
}

.sidenav-icon-size-css {
  font-size: 24px;
  margin-right: 0.4em;
}

.confirm-cancel-button-css {
  background: rgba(78, 119, 241, 0.9);
  border-radius: 2em;
  padding: 1em;
  width: 150px;
  color: #fff;
}

.confirm-cancel-button-css:hover {
  background: rgba(78, 119, 241, 0.9);
  border-radius: 2em;
  padding: 1em;
  width: 150px;
  color: #fff;
}

.unstake-button-css {
  background: rgba(78, 119, 241, 0.9);
  border-radius: 1em;
  padding: 1em;
  width: 150px;
  color: #fff;
}

.unstake-button-css:hover {
  background: rgba(78, 119, 241, 0.9);
  border-radius: 1em;
  padding: 1em;
  width: 150px;
  color: #fff;
}

/*==============dashboard-css==================*/
@import url("https://fonts.googleapis.com/css?family=Quattrocento+Sans");
@import url("https://fonts.googleapis.com/css?family=Oswald");



.outer-circle {
  background-image: linear-gradient(#89b3ee, #3172c7, #203c8ee6);
  width: 250px;
  height: 250px;
  border-style: solid;
  border-color: transparent;
  border-radius: 50%;
  border-width: 0;
  -webkit-animation: rotateCircle 2s linear infinite;
  animation: rotateCircle 2s linear infinite;
  padding: 2px;
  display: table;
}

.outer-circle .inner-circle {
  background: #fff;
  height: 250px;
  width: 250px;
  border-style: solid;
  border-color: transparent;
  border-radius: 50%;
  border-width: 1px;
  animation: rotateCircle 2s linear infinite reverse;
  display: table;
  text-align: center;
}

.outer-circle .text {
  vertical-align: middle;
  display: table-cell;
  padding: 0 20px;
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: 600;

}

@-webkit-keyframes rotateCircle {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotateCircle {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Example CSS */
.account-balance-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  font-size: 20px;
  color: #333;
}

.account-balance-label {
  font-weight: bold;
}

.account-balance-amount {
  font-size: 28px;
  color: #008080;
  margin-top: 10px;
}


/* TABLE CSS */


/* Table.css */
.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #f2f2f2;
}

.table th,
.table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

.table th {
  /* background-color: #f2f2f2; */
  background-color: #bc9018;
  padding: 15px 20px;
  font-size: 14px;
}

.table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tbody tr:hover {
  background-color: #ddd;
}

.navigationButtons li {
  list-style: none;
}

.navigationButtons {
  display: flex;
  margin: auto;
  gap: 20px;
}

.pagination-buttons {
  display: flex;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>> */
/* <<<<<<<<<<<<<<<<<<error>>>>>>>>>>>>>>>>>>>>>>> */

/*======================
    404 page
=======================*/


.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: 'Arvo', serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {

  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}


.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}

.treasure-btn-1 {
  border: 1px solid var(--primary-color);
  padding: 10px 20px;
  text-transform: capitalize;
  background-color: #d79332;
  color: black;
  border-radius: 10px;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.section {
  padding: 4rem 2rem;
}

.section .error {
  font-size: 150px;
  color: #d79332;
  text-shadow:
    1px 1px 1px #d79332,
    2px 2px 1px #d79332,
    3px 3px 1px #d79332,
    4px 4px 1px #d79332,
    5px 5px 1px #d79332,
    6px 6px 1px #d79332,
    7px 7px 1px #d79332,
    8px 8px 1px #d79332,
    25px 25px 8px rgba(0, 0, 0, 0.2);
}

.page {
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
  color: #d79332;
}

.back-home {
  display: inline-block;
  border: 2px solid #222;
  color: #222;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

.back-home:hover {
  background: #222;
  color: #ddd;
}

.page-link {
  background-color: white !important;
  color: #000000;
}

.active>.page-link {
  background-color: #d79332 !important;
  color: #000000 !important;

}

.page-link:focus {
  box-shadow: none !important;
}

.rdt_TableCol_Sortable {
  justify-content: center !important;
}

.rdt_TableCell {
  justify-content: center !important;
  white-space: nowrap !important;
}

.rdt_TableCol {
  background-color: #bc9018;
  padding: 15px 0px !important;
  font-size: 14px;
}

.add-subadmin-section .sub-check-1:checked {
  background-color: #bc9018 !important;
}

/* <<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
::placeholder {
  color: black !important;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: black !important;
}

.top-modal-1 {
  position: absolute;
  right: 16px;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<custom-inside>>>>>>>>>>>>>>>> */
.custom-site-setting h2 {
  color: var(--primary-color);
}

.custom-inside {
  background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%));
}

.custom-input-1 {
  border-color: #dee2e6;
}

.custom-input-1:focus {
  border-color: #dee2e6 !important;
}

.custom-inside {
  border: 0px solid red;
  padding: 35px;
  border-radius: 20px;
  color: white;
}

.custom-cup-input {
  border: 0px solid #bc9018;
  padding: 20px;
  /* background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%)); */
  border-radius: 20px;
  color: #bc9018;

  box-shadow: rgb(217 191 112 / 50%) 6px 2px 16px 0px, rgb(165 140 65 / 50%) -6px -2px 16px 0px;

}

.custom-cup-modal-1 {
  /* background: var(--Linear, linear-gradient(180deg, #EAC368 0%, #D2B14A 100%)); */
  color: #bc9018;
}

.custom-cup-body-1 {
  border: 1px solid #bc9018;
  margin: 10px;
  border-radius: 10px;
}


.site-admin-add-1 {
  border: 0px solid #bc9018;
  padding: 20px;
  border-radius: 10px;
  color: #bc9018;

  box-shadow: rgb(217 191 112 / 50%) 6px 2px 16px 0px, rgb(165 140 65 / 50%) -6px -2px 16px 0px;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>> */
.close-btn-1:hover {
  color: #bc9018 !important;
}

.close-btn-1 {
  color: black;
  background-color: transparent;
  border: 0;
  font-size: 25px;
}

.input-reason {
  width: 80%;
}

@media (min-width:280px) and (max-width:991px) {
  .input-reason {

    width: 58%;
  }
}

label {
  pointer-events: none;
}

lable {
  cursor: default;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<customer-inside-wallet-balance>>>>>>>>>>>>>>>>>>>>>>> */
.customer-inside-wallet-balance {
  border: 0px solid #bc9018;
  padding: 20px 20px;
  border-radius: 20px;
  margin-left: 0px;

  box-shadow: rgb(217 191 112 / 50%) 6px 2px 16px 0px, rgb(165 140 65 / 50%) -6px -2px 16px 0px;

}

.customer-inside-wallet-balance p {
  color: #bc9018;
}

/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<end>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.form-check-input:focus {
  box-shadow: rgb(217 191 112 / 50%) 6px 2px 16px 0px, rgb(165 140 65 / 50%) -6px -2px 16px 0px !important;
  border-color: #bc9018 !important;
}

.form-check-input:checked {
  border-color: #bc9018 !important;
}
/* .testing-01,.testing-02{
  margin-top:-55px;
} */
.table-download{
  color:#bc9018;
}
.submitSpan {
  color: #bc9018;
}
.info-btn{
  background-color: white;
  color: red;
  border: 0px solid #bc9018;
  border-radius: 10px;
  font-size: 30px;
}

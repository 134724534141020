.canvasjs-chart-credit{
    color: transparent !important;
}
.canvasjs-chart-label {
    display: block !important;
    z-index: 10;
  }
  .dashboard-btn-1 {
    border: 1px solid #bc9018;
    padding: 10px 40px;
    text-transform: capitalize;
    background-color: white;
    color: #bc9018;
    border-radius: 10px;
    font-size: 16px;
    text-transform: uppercase;
  }
  .dashboard-btn-1.active {
    background-color: #bc9018 !important;
    color: white !important;
  }
  .activations{
    background-color: #bc9018;
    color: white !important;
  }